/* todo.css */

/* Container Styles */
.todo-container {
  width: 90%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

.text {
  text-align: center;
  margin-bottom: 20px;
}

.text h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 8px;
}

.text p {
  font-size: 14px;
  color: #777;
}

/* Tabs Section */
.tabsFeel {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.tab {
  padding: 8px 12px;
  margin: 0 5px;
  font-size: 14px;
  color: #333;
  background-color: #ddd;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.tab:hover {
  background-color: #bbb;
}

.tab.selected {
  background-color: #007bff;
  color: #fff;
}

/* Task List Section */
.todo-task-list {
  padding: 10px 0;
}

.todo-task {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.task-name {
  font-size: 16px;
  color: #333;
}

.task-name.completed {
  text-decoration: line-through;
  color: #999;
}

.task-actions {
  display: flex;
  align-items: center;
  gap: 8px;
}

.task-completed-checkbox {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

/* Button Styles */
button {
  font-size: 14px;
  padding: 6px 10px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.edit-task-button {
  background-color: #007bff;
  color: white;
}

.edit-task-button:hover {
  background-color: #0056b3;
}

.delete-task-button {
  background-color: #dc3545;
  color: white;
}

.delete-task-button:hover {
  background-color: #c82333;
}

.save-button {
  background-color: #28a745;
  color: white;
}

.save-button:hover {
  background-color: #218838;
}

.cancel-button {
  background-color: #6c757d;
  color: white;
}

.cancel-button:hover {
  background-color: #5a6268;
}

/* Input Section */
.todo-input-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.todo-input,
.todo-date-input,
.todo-time-input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.todo-input {
  margin-bottom: 10px;
}

.todo-add-button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s;
}

.todo-add-button:hover {
  background-color: #0056b3;
}

/* Placeholder for Empty Task List */
.todo-task-list p {
  font-size: 16px;
  text-align: center;
  color: #666;
}
